import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css, useTheme } from 'styled-components'
import { motion } from 'framer-motion'
import Text from './Text'
import { useAppContext } from '../../context/AppContext'
import useTelemetry from '../../hooks/useTelemetry'
import { radToDeg } from '../../utils/maths'

const DRAG_FRICTION = 0.04

const ProjectTitle = ({ title, subTitle, vertical, titleAs, subTitleAs }) => {
  const theme = useTheme()
  const container = useRef()
  const { x, y } = useTelemetry()
  const { isLarge } = useAppContext()

  const sharedProps = {
    title: {
      xs: 60,
      lg: 80,
      xl: 80,
    },
    subTitle: {
      xs: 30,
      lg: 40,
      xl: 40,
    },
  }

  return (
    <Container
      ref={container}
      style={{
        transform: `translate(${-x * DRAG_FRICTION}px,${-y * DRAG_FRICTION}px) ${vertical ? ' rotate(270deg)' : ''}`,
      }}
    >
      <Text
        nowrap={isLarge}
        uppercase
        as={motion[titleAs]}
        left
        center={isLarge}
        animate={{ opacity: 1, color: theme.color.primary }}
        thin
        {...sharedProps.title}
      >
        {title}
      </Text>
      <div>
        <Text
          nowrap
          uppercase
          as={motion[subTitleAs]}
          center
          animate={{ opacity: 1, color: theme.color.primary }}
          serif
          spacing={isLarge ? 8 : 3}
          {...sharedProps.subTitle}
          style={{
            transform: `translate(${-x * DRAG_FRICTION}px,${-y * DRAG_FRICTION}px)`,
          }}
        >
          {subTitle}
        </Text>
      </div>
    </Container>
  )
}

ProjectTitle.propTypes = {
  vertical: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  titleAs: PropTypes.string,
  subTitleAs: PropTypes.string,
}

ProjectTitle.defaultProps = {
  rotate: 0,
  titleAs: 'h1',
  subTitleAs: 'h2',
}

export default ProjectTitle

const Container = styled(motion.div)`
  ${({ theme }) => css`
    position: relative;
    user-select: none;
    pointer-events: none;
    z-index: 100;
    padding-bottom: ${theme.space(30)};

    > * {
      > * {
        opacity: 0;
      }
      &:last-child {
        position: absolute;
        top: -20px;
        left: 0;
        ${theme.media.lg`
          top:0 ;
          transform: translate(-50%,-80%)
        `}
      }
    }
  `}
`
