import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { graphql } from 'gatsby'

import { Row, Col } from 'react-flexa'
import Title from '../common/Title'
import Paragraph from '../common/Paragraph'
import { useAppContext } from '../../context/AppContext'
import ProjectTitle from '../common/ProjectTitle'
import { motion } from 'framer-motion'

const Project = ({ data: { project }, pageContext: { theme } }) => {
  const { media, types, description, title, subTitle } = project
  const {
    image: { setImage },
    gallery: { setGalleryContent, galleryOpen },
    setColorMode,
  } = useAppContext()
  const type = types[0]?.projectType || 'PROJECT'
  const featuredImage = useMemo(() => {
    let img = media.find(({ customData: { featured } }) => featured?.toLowerCase() === 'true')?.src
    if (!img) {
      img = media[0]?.fixed?.src
    }
    return img
  }, [media])

  useEffect(() => {
    setGalleryContent(project.media)
  }, [project.media, setGalleryContent, galleryOpen])

  useEffect(() => {
    setColorMode(theme)
  }, [setColorMode, theme])

  useEffect(() => {
    setImage(featuredImage)
  }, [featuredImage, setImage])
  return (
    <Container>
      <StyledRow justifyContent="flex-start">
        <TitleCol display="flex" xs={12} lg={4}>
          <TitleContainer>
            <Title vertical as="h3">
              {type}
            </Title>
          </TitleContainer>
        </TitleCol>
        <SubTitleCol display="flex" xs={12} lg={4}>
          <ProjectTitleContainer as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <ProjectTitle title={title} subTitle={subTitle} />
          </ProjectTitleContainer>
        </SubTitleCol>
        <BodyCol xs={12} lg={4}>
          <StyledRow flexDirection="column" justifyContent={{ xs: 'flex-start', lg: 'center' }} alignItems="center">
            <ParagraphContainer as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Paragraph html>{description}</Paragraph>
            </ParagraphContainer>
          </StyledRow>
        </BodyCol>
      </StyledRow>
    </Container>
  )
}

Project.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default Project

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 100vh;
    position: relative;
    min-height: 680px;
    ${theme.media.md`
      min-height: 100%;
    `}
  `,
)

const ParagraphContainer = styled.div(
  ({ theme }) => css`
    padding-top: ${theme.space(1)};
    position: relative;
    z-index: 10;
    ${theme.media.lg`
      padding-top: 20vh;
    `}
    > * {
      &:last-child {
        margin-top: ${theme.space(4)};
        ${theme.media.lg`
          margin-top: ${theme.space(8)};
        `}
      }
    }
  `,
)

const TitleContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    transform: translate(-48%, calc(50vh + -50%));
    z-index: 12;

    ${theme.media.lg`
      transform: translate(-48%, 20%);
    `}
  `,
)
const ProjectTitleContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    z-index: 12;
    pointer-events: none;

    top: ${theme.space(6)};
    left: ${theme.space(1)};
    ${theme.media.lg`
      top:0;
      left:0;
    `}
  `,
)

const StyledRow = styled(Row)(
  ({ theme }) => css`
    overflow: hidden;
    height: 100%;
  `,
)

const TitleCol = styled(Col)(
  ({ theme }) => css`
    position: relative;
    height: 33%;
    align-items: center;
    align-content: center;
    ${theme.media.lg`
      height: 100%;
    `}
  `,
)

const SubTitleCol = styled(TitleCol)(
  ({ theme }) => css`
    position: relative;
    height: 33%;
    align-items: center;
    align-content: center;
    ${theme.media.lg`
      height: 100%;
    `}
  `,
)

const BodyCol = styled(TitleCol)(
  ({ theme }) => css`
    height: 33%;
    ${theme.media.lg`
      height: 100%;
    `}
  `,
)

export const query = graphql`
  query KitchenData($id: String) {
    project: datoCmsProject(id: { eq: $id }) {
      ... on DatoCmsProject {
        id
        path
        description
        types {
          projectType
        }
        subTitle
        title
        media {
          customData
          fixed(width: 1024, imgixParams: { fm: "jpg", auto: "compress" }) {
            src
          }
        }
      }
    }
  }
`
